var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simple"},[_c('b-card-code',{attrs:{"title":"إضافة شخص غير مسجل"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("الأسم")]),_c('b-form-input',{attrs:{"id":"helperInput","state":errors.length > 0 ? false : null,"placeholder":"الاسم"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"fname"}},[_c('validation-provider',{attrs:{"name":"fname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("أسم الأب")]),_c('b-form-input',{attrs:{"id":"helperInput","state":errors.length > 0 ? false : null},model:{value:(_vm.FName),callback:function ($$v) {_vm.FName=$$v},expression:"FName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"lname"}},[_c('validation-provider',{attrs:{"name":"lname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("النسبة ")]),_c('b-form-input',{attrs:{"id":"helperInput","state":errors.length > 0 ? false : null},model:{value:(_vm.FamilyName),callback:function ($$v) {_vm.FamilyName=$$v},expression:"FamilyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"mname"}},[_c('validation-provider',{attrs:{"name":"mname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("أسم الأم")]),_c('b-form-input',{attrs:{"id":"helperInput","state":errors.length > 0 ? false : null},model:{value:(_vm.Mname),callback:function ($$v) {_vm.Mname=$$v},expression:"Mname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('validation-provider',{attrs:{"name":"familyNatural","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"familyNatural","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" طبيعة العائلة")]),_c('v-select',{attrs:{"options":_vm.FamilyStatus,"label":"name","reduce":function (val) { return val.id; }},model:{value:(_vm.family_status_id),callback:function ($$v) {_vm.family_status_id=$$v},expression:"family_status_id"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.family_status_id !== 2)?_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('validation-provider',{attrs:{"name":"fname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"date","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v("تاريخ النزوح")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"حدد تاريخ النزوح","config":{ maxDate: _vm.maxDate }},model:{value:(_vm.displacement_date),callback:function ($$v) {_vm.displacement_date=$$v},expression:"displacement_date"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,649942565)})],1):_vm._e(),_c('b-col',{attrs:{"md":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"bplace"}},[_c('validation-provider',{attrs:{"name":"field","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" مكان الولادة")]),_c('b-form-input',{attrs:{"id":"helperInput","state":errors.length > 0 ? false : null},model:{value:(_vm.PlaceBir),callback:function ($$v) {_vm.PlaceBir=$$v},expression:"PlaceBir"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"bplace"}},[_c('validation-provider',{attrs:{"name":"field","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" الرقم الوطني")]),_c('b-form-input',{attrs:{"id":"helperInput","state":errors.length > 0 ? false : null},model:{value:(_vm.national_no),callback:function ($$v) {_vm.national_no=$$v},expression:"national_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"BirthDate","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" تاريخ الميلاد")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"حدد تاريخ الميلاد","config":{ maxDate: _vm.max }},model:{value:(_vm.dateOfBirth),callback:function ($$v) {_vm.dateOfBirth=$$v},expression:"dateOfBirth"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('validation-provider',{attrs:{"name":"Contact","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"رقم الهاتف","label-for":"contact","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"id":"contact"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('validation-provider',{attrs:{"name":"select","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"time","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" الجنس")]),_c('v-select',{attrs:{"reduce":function (val) { return val.value; },"options":_vm.optiongender},on:{"input":function($event){return _vm.getWorkType(_vm.selectgender)}},model:{value:(_vm.selectgender),callback:function ($$v) {_vm.selectgender=$$v},expression:"selectgender"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('validation-provider',{attrs:{"name":"social status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"time","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" الحالة الأجتماعية")]),_c('v-select',{attrs:{"reduce":function (val) { return val.value; },"options":_vm.MartialState},model:{value:(_vm.selectstatus),callback:function ($$v) {_vm.selectstatus=$$v},expression:"selectstatus"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('validation-provider',{attrs:{"name":"studylevel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" التحصيل العلمي")]),_c('v-select',{attrs:{"reduce":function (val) { return val.value; },"options":_vm.optionStudy},model:{value:(_vm.selectedStudy),callback:function ($$v) {_vm.selectedStudy=$$v},expression:"selectedStudy"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('validation-provider',{attrs:{"name":"studystate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" حالة التعليم")]),_c('v-select',{attrs:{"reduce":function (val) { return val.value; },"options":_vm.EducationalState},model:{value:(_vm.selectedStudyState),callback:function ($$v) {_vm.selectedStudyState=$$v},expression:"selectedStudyState"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('validation-provider',{attrs:{"name":"NewWork","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" العمل الحالي")]),_c('v-select',{attrs:{"reduce":function (val) { return val.value; },"options":_vm.optionJobs},model:{value:(_vm.selectedWork),callback:function ($$v) {_vm.selectedWork=$$v},expression:"selectedWork"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('validation-provider',{attrs:{"name":"LastWork","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" العمل السابق")]),_c('v-select',{attrs:{"reduce":function (val) { return val.value; },"options":_vm.optionJobs},model:{value:(_vm.selectedLastWork),callback:function ($$v) {_vm.selectedLastWork=$$v},expression:"selectedLastWork"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6","xl":"3"}},[_c('validation-provider',{attrs:{"name":"Nationality","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"time","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" الجنسية")]),_c('v-select',{attrs:{"reduce":function (val) { return val.value; },"options":_vm.optionNationality},model:{value:(_vm.selectedNa),callback:function ($$v) {_vm.selectedNa=$$v},expression:"selectedNa"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"number"}},[_c('label',[_vm._v(" ملاحظات عامة")]),_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"الملاحظات","rows":"1"},model:{value:(_vm.singleNotes),callback:function ($$v) {_vm.singleNotes=$$v},expression:"singleNotes"}})],1)],1)],1)],1)],1)],1),_c('b-button',{attrs:{"variant":"purple"},on:{"click":_vm.save}},[_c('span',{staticClass:"align-middle"},[_vm._v(" إضافة ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }